<!-- 语法 -->
<template>
    <div class="">
         <fc3dhe></fc3dhe>
        <fc3dfu></fc3dfu>
        <fc3ddan></fc3ddan>
        <fc3dwei></fc3dwei>
    </div>
</template>

<script>
       import fc3dfu from '@/components/wanfa/fc3dfu.vue'
    import fc3ddan from '@/components/wanfa/fc3ddan.vue'
    import fc3dhe from '@/components/wanfa/fc3dhe.vue'
    import fc3dwei from '@/components/wanfa/wei.vue'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    //import api from '@/js/method'
    export default {
        name: '',
        components: {
            fc3dfu,
            fc3ddan,
            fc3dhe,
            fc3dwei
        },
        data() { // 数据源
            return {
               
            }
        },
        mounted() {

        },
        computed: {
         
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: []
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>