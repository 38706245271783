<!--  -->
<template>
  <div class="">
    <div class="ssq-table">
      <div class="ssq-table-header">
        <div class="ssq-icon">
          <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2538" width="45" height="45">
            <path
              d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
              fill="#ffffff" p-id="2539"></path>
            <path
              d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
              fill="#ffffff" p-id="2540"></path>
            <path
              d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
              fill="#ffffff" p-id="2541"></path>
          </svg>
          <div class="ssq-one">
            <h2>中国福利彩票</h2>
          </div>
          <div class="ssq-two">
            <h3>福 彩 3D 位 选 投 注 表</h3>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="wei0">(位选)点击号码查看金额</div>
      <div class="wei0">
        <div class="wei">百位:</div>
        <div :class="xred1.indexOf(i) == -1 ? 'weiball' : 'red'" v-for="(item, i) in 10" :key="i" @click="ball(0, i)">
          {{ item-1 }}
        </div>
        <div class="wei">十位:</div>
        <div :class="xred2.indexOf(i) == -1 ? 'weiball' : 'red'" v-for="(item, i) in 10" :key="i" @click="ball(1, i)">
          {{ item-1 }}
        </div>
        <div class="wei">个位:</div>
        <div :class="xred3.indexOf(i) == -1 ? 'weiball' : 'red'" v-for="(item, i) in 10" :key="i" @click="ball(2, i)">
          {{ item-1 }}
        </div>
        <div class="clear"></div>
      </div>
      <div class="jine" @click="del()" style="
          border: 1px solid #c0c0c0;
          width: 80px;
          margin: 0 auto;
          margin-top: 20px;
        ">
        清空
      </div>
      <div class="jine">
        金额：{{ xred1.length * xred2.length * xred3.length * 2 }}元
      </div>
    </div>
  </div>
</template>

<script>
  //import axios from 'axios'
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  export default {
    name: "qlc-basic",
    components: {},
    data() {
      // 数据源
      return {
        xred: [],
        xred1: [],
        xred2: [],
        xred3: [],
      };
    },
    mounted() {
      this.toTop();
    },
    computed: {},
    methods: {
      // 函数体部分，js的主要逻辑控制
      toTop() {
        document.documentElement.scrollTop = 0;
      },
      mon(min, max, wangs, langs) {
        var s = 1;
        for (var n = min - 1; n < max; n++) {
          s = s * (n + 1);
        }
        for (var n1 = wangs; n1 > 0; n1--) {
          s = s / n1;
        }
        return s * langs * 2;
      },
      MO(a, b) {
        return Math.abs(a - b);
      },
      //号码点击选择函数
      ball(x, i) {
        if (x == 0) {
          if (this.xred1.indexOf(i) == -1) {
            this.xred1.push(i);
            // this.arr0.push(i + 1);
          } else {
            this.xred1.splice(this.xred1.indexOf(i), 1);
            // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
          }
        } else if (x == 1) {
          if (this.xred2.indexOf(i) == -1) {
            this.xred2.push(i);
            // this.arr0.push(i + 1);
          } else {
            this.xred2.splice(this.xred2.indexOf(i), 1);
            // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
          }
        } else if (x == 2) {
          if (this.xred3.indexOf(i) == -1) {
            this.xred3.push(i);
            // this.arr0.push(i + 1);
          } else {
            this.xred3.splice(this.xred3.indexOf(i), 1);
            // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
          }
        }
        console.log("xred1:" + this.xred1);
        console.log("xred2:" + this.xred2);
        console.log("xred3:" + this.xred3);
      },
      del() {
        this.xred1 = [];
        this.xred2 = [];
        this.xred3 = [];
      },
    },
    props: ["wei"],
    watch: {},
  };
</script>

<style scoped>
  /* @import './swiper.css'; */
  /* * {
  border: 1px solid red;
} */
  .ssq-table-header {
    float: left;
    width: 100%;
    height: 50px;
    background-color: red;
  }

  .tab {
    margin: 0 auto;
    border-collapse: collapse;
  }

  .ssq-icon {
    text-align: left;
  }

  .icon {
    float: left;
  }

  .ssq-one {
    float: left;
    height: 42px;
    line-height: 42px;
    color: #fff;
  }

  .ssq-two {
    float: left;
    width: 80%;
    text-align: center;
    line-height: 42px;
    font-size: 25px;
    color: rgb(252, 251, 249);
  }

  tbody .xuan {
    background-color: rgb(160, 156, 156);
    border-radius: 15px;
    color: rgb(253, 253, 253);
  }

  .wei0 {
    width: 31%;
    margin-left: 35%;
    font-size: 15px;
  }

  .wei {
    float: left;
    margin-top: 30px;
    font-size: 15px;
  }

  .weiball {
    float: left;
    margin-top: 30px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    background-color: rgb(180, 173, 173);
    border-radius: 15px;
    color: #fff;
  }

  .red {
    float: left;
    margin-top: 30px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    background-color: rgb(250, 8, 8);
    border-radius: 15px;
    color: #fff;
  }

  .jine {
    font-size: 15px;
    margin-top: 20px;
  }

  .no {
    display: none;
  }
</style>